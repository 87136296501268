import React from 'react'

import Header from './Header';
import { useLocation } from 'react-router-dom';

const Messages = () => {
const location = useLocation(); // Access the passed data from the location state
const data = location.state?.data || {};
    return (

        <>
            <Header info={data.fname+' '+data.lname} info1={data.devicename} />
            

                <div class="chat-bg"> 

                     <div class='chat-icon'>  
                       <i class="fa fa-comment-o"  ></i>
                       </div> 
                     <h1>No Chats</h1>




                </div> 

       


        </>
    )

}

export default Messages;
