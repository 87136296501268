import React, { useState } from 'react';
import {auth} from '../firebase'
import { createUserWithEmailAndPassword } from 'firebase/auth';
import {useNavigate } from 'react-router-dom'
import { db } from '../firebase';
// import { addDoc, collection } from "firebase/firestore"; 
import { doc, setDoc } from "firebase/firestore"; 
// import Header from './Header.js'
import "./Loginregister.css"
import Logo from './Logo';
import ReCAPTCHA from "react-google-recaptcha";

const LoginForm = () => {
  
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpwd, setConfirmpwd] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState(null); // Add this state
  const navigate = useNavigate();
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };

  const handleLnameChange = (e) => {
    setLname(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordsMatch(e.target.value === confirmpwd);
  };

  const handleConfirmpwdChange = (e) => {
    setConfirmpwd(e.target.value);
    setPasswordsMatch(password === e.target.value);
  };
  
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  

  const handleSubmit =async (e) => {
    e.preventDefault();
    if (!recaptchaValue) {
      alert("! Invalid Recaptcha.");
      return;
    }
    // Add your authentication logic here (e.g., API call, validation, etc.)
    console.log('Email:', email);
    console.log('Password:', password);
    console.log('first name:',fname);
    console.log('last name:',lname);

    createUserWithEmailAndPassword(auth,email,password)
    .then((userCredential)=>{
        console.log(userCredential.user.uid)
         setDoc(doc(db, "AigesCollection",userCredential.user.uid), {
            fname: fname,
            lname: lname,
            confirmpwd: confirmpwd            
          });
          navigate("/")
    })
    .catch((error) => {
        console.error(error);
    });
  };


  return (

   
   <div className="background-section">
        <div className="middle-section">
            <div className="block-section">
                <div className="logosize">
                    {/* <img src="logo.png" alt="Logo"/> */}
                    <Logo/>
                </div>
                <form onSubmit={handleSubmit}>
                <h2>REGISTER</h2>
                <div className="form-group">
                    <label for="firstName">First Name</label>
                    <input type="text" 
                    id="fname" 
                    value={fname} onChange={handleFnameChange} 
                    placeholder="First Name"/>
                </div>
                <div className="form-group">
                    <label for="lastName">Last Name</label>
                    <input type="text" id="lname"
                     value={lname} onChange={handleLnameChange} 
                     placeholder="Last Name"/>
                </div>
                <div className="form-group">
                    <label for="email">Email</label>
                    <input type="email" id="email"
                     value={email} onChange={handleEmailChange}
                     placeholder="Email" required/>
                </div>
                <div className="form-group">
                    <label for="password">Password</label>
                    <input type="password" id="password" 
                    value={password} onChange={handlePasswordChange}
                    placeholder="Password" required/>
                </div>
                <div className="form-group">
                    <label for="confirmPassword">Confirm Password</label>
                    <input type="password" id="confirmPassword" 
                    value={confirmpwd} onChange={handleConfirmpwdChange}
                    placeholder="Confirm Password"/>
                    {!passwordsMatch && <span style={{ color: 'red',fontSize: '13px' }}>Password confirmation doesn't match.</span>}
                </div>
                <div >
                <div className="form-group">
                  <ReCAPTCHA
                    sitekey=" 6LfnihkpAAAAAKvCjZPg4FXAG3W3ZC51eUmPh_pi"
                    onChange={handleRecaptchaChange}
                  />
                </div>
                        
                </div>
                <div className="form-group">               
                <a href="/Login" className="Back-to-Login">Back to Login</a>
                <button className="create-account">Create an Account</button>
                </div>
                
                

                <p1>By signing up, I hereby agree to the <a href="terms.html">Terms</a> and <a href="privacy.html">Privacy Policy</a>.</p1>
                </form>  
            </div>
        </div>
    </div>
    
  
    
    
  );
};

export default LoginForm;
