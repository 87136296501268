import React from 'react';
import { useState } from 'react';
import Logo from './Logo';
import 'font-awesome/css/font-awesome.min.css';
import './style.css';
import "./navbar.css";
import Modal from 'react-modal';
import {  Link } from 'react-router-dom';

const Header = (props) => {
  const receivedInfo = props.info;
  const receivedInfo1 = props.info1;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSDropdownVisible, setSDropdownVisible] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openNotificationModal = () => {
    setIsNotificationModalOpen(true);
  };

  const closeNotificationModal = () => {
    setIsNotificationModalOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleSDropdown = () => {
    setSDropdownVisible(!isSDropdownVisible);
  };

  return (
    <>
      <nav className="navbar">
        <div className="left">
          <div className="logo"><Logo /></div>
        </div>
        <div className="center">
          <ul className="menu">
            
            <li><Link to="/Dashboard"><i className="fa fa-home fa-lg"></i>Dashboard</Link></li>
            <div
              className={`dropdown ${isDropdownOpen ? 'open' : ''}`}
              onMouseEnter={toggleDropdown}
              onMouseLeave={toggleDropdown}
            >
              <a href="#Data">
                <i className="fa fa-qrcode"></i> Data
              </a>
              <div className="dropdown-content">
              
              <li><Link to="/Contact"><i className="fa fa-address-book-o fa-lg"></i>Contacts</Link></li>
              <li><Link to="/Media"><i className="fa fa-folder-o fa-lg"></i>Media</Link></li>
              <li><Link to="/Messages"><i className="fa fa-commenting-o fa-lg"></i>Messages</Link></li>
              <li><Link to="/Callhistory"><i className="fa fa-phone fa-lg"></i>Call History</Link></li>
              <li><Link to="/Location"><i className="fa fa-map-marker fa-lg"></i>Location History</Link></li>
                
              </div>
            </div>
            <li><Link to="/Calling"><i className="fa fa-id-card-o fa-lg"></i>Calling Circle</Link></li>
            <a href="/Location"><i className="fa fa-download fa-lg"></i> Download</a>
            <li><Link to="/Location"><i className="fa fa-download fa-lg"></i>Download</Link></li>
          </ul>
        </div>
        <div className="right">
          <div className='rightuser'>
            <div className="row">
              <span className="devices_name">
                <a href="#Mobile">
                  <i className="fa fa-mobile" onClick={openModal}></i>
                </a>
               {receivedInfo1}
              </span>
              <span className="user-icon">
                <i className="fa fa-bell-o" onClick={openNotificationModal}></i>
              </span>
              <Modal
                isOpen={isNotificationModalOpen}
                onRequestClose={closeNotificationModal}
                contentLabel="Notification Modal"
                style={{
                  content: {
                    backgroundColor: 'white',
                    width: '25%',
                    height: '45vh',
                    top: '33%',
                    left: '50%',
                    marginLeft: '30%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '01rem',
                    paddingBottom: '0%',
                    marginBottom: '2px',
                    overflowY: 'hidden',
                  }
                }}
              >
                <div className='modal-overlay'>
                  <button className="close-button" onClick={closeNotificationModal}>
                    X
                  </button>
                  <div className="topHeding">
                    <h2 className='headingCnt'>Notification</h2>
                  </div>
                  <div className='notification-bell'><i className="fa fa-bell-o"></i></div>
                  <div><h3>No Notification</h3></div>
                  <div className='notf-Cont'><p >Hello, This is your notification message!</p></div>
                </div>
              </Modal>
              <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={{
                  content: {
                    backgroundColor: 'white',
                    width: '50%',
                    height: '40vh',
                    top: '47%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '01rem',
                    paddingBottom: '0%',
                    marginBottom: '2px',
                    overflowY: 'hidden',
                  }
                }}
              >
                {/* Your modal content goes here */}
                <div className='modal-overlay'>
                  <button className="close-button" onClick={closeModal}>
                    X
                  </button>
                  <div className="topHeding" >
                    <h2 className='headingCnt'>Select Device</h2>
                  </div>
                  <div className="media-dropdown-call">
                    <label htmlFor="priority" style={{ marginTop: "44px", marginLeft: "8px" }}>Select Device</label>
                    <select type="text" id="priority" className="media-dropdown-border" style={{ width: '280%', marginLeft: "4px" }}>
                      <option value="high">Select Device</option>
                      <option value="low">Redmi M2101K7AI</option>
                    </select>
                  </div>
                  <div className='btncntContainer'>
                    <button className='cl-button'>Close</button>
                    <button className='save-button' style={{ marginLeft: "8px" }}>Save</button>
                  </div>
                </div>
              </Modal>
              <div className={`dropdown ${isSDropdownVisible ? 'open' : ''}`}>
                <a href="" onClick={toggleSDropdown}>
                  <div className="user-avatar" onMouseEnter={toggleSDropdown} onMouseLeave={toggleSDropdown}>{receivedInfo.charAt(0)}</div>
                </a>
                {isSDropdownVisible && (

                <div className="user-dropdown-content" >
                  <a href="#D">Signed in as<br />{receivedInfo}</a>
                  
                  <a href="/Profile"><i className="fa fa-user-circle"></i>My Account</a>
                  <hr className="line" />
                  <a href="/Purchases"><i className="fa fa-clone"></i>Purchases </a>
                  <hr className="line" />
                  <a href="#D"><i className="fa fa-gear"></i>Help Center</a>
                  <hr className="line" />
                  <a href="/"><i className="fa fa-sign-out"></i>Sign Out</a>
                </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
