import React from 'react'
import { db } from '../firebase';
import { useState } from 'react';
import { collection, getDocs} from "firebase/firestore";
import { useEffect, useRef } from "react";
//import "./navbar.css"
import { query, orderBy, limit } from "firebase/firestore"; 
import Header from './Header.js'
import { useLocation } from 'react-router-dom';


const Contact = () => {
    const location = useLocation(); // Access the passed data from the location state
    const data = location.state?.data || {};
    const wasCalled = useRef(false);
    const [info, setInfo] = useState([]);
 
    // Start the fetch operation as soon as
    // the page loads
   
    useEffect(() => {
        if(wasCalled.current) return;
            wasCalled.current = true;
        Fetchdata();
      }, []);

    // Fetch the required data using the get() method
    const Fetchdata = async () => {

       
        
        const q = query(collection(db, "callingnumber"), orderBy("name","desc"), limit(100));
        const querySnapshot = await getDocs(q);
       // console.log(q);
        querySnapshot.forEach(element => {
            var data = element.data();
            setInfo(arr => [...arr, data]);

        });

        // db.collection("callingnumbers").get().then((querySnapshot) => {
 
        //     // Loop through the data and store
        //     // it in array to display
        //     querySnapshot.forEach(element => {
        //         var data = element.data();
        //         setInfo(arr => [...arr, data]);
 
        //     });
        // })
    }


    // Display the result on the page
    return (
        
        <div className="contact-container">
             <Header info={data.fname+' '+data.lname} info1={data.devicename}/>
           <h2 style={{textAlign:'center'}}>Contact Details</h2>
           <div className="search-bar">
            <input type="text" placeholder="Search contacts"/>
        </div>
        <table className="contact-table">
 
            {
                info.map((data) => (
                    <Frame id={data.id}
                        name={data.name}
                        desc={data.desc} />
                ))
            }

          </table>  
        </div>
 
    );
}
 
// Define how each display entry will be structured
const Frame = ({ id, name, desc }) => {
    console.log(id + " " + name + " " + desc);
    return (

    
           

           <tr>
                
                <td className="contact-cell">
                    <span className="icon">{id}</span>
                    {name}
                    <td><i className="fa fa-phone"></i> {desc}</td>
                    <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-heart" viewBox="0 0 16 16">
                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                    </svg></td>
                </td>
            </tr>

            
    );
}
 
export default Contact;