import React from 'react'

import Header from './Header';
import { useLocation } from 'react-router-dom';

const Addcalling = () => {
    const location = useLocation(); // Access the passed data from the location state
    const data = location.state?.data || {};
    return (

        <>
            <Header info={data.fname+' '+data.lname} info1={data.devicename} />
            <div class='main-sec'>
                <header>
                    
                    <h1>Add Calling Circle</h1>

                </header>

                <div class="container">

                    <div class="horizontal-block">
                        <h2>Basic Details</h2>
                        <div class="flex-container">
                            <div class="text-field">
                                <label for="callingName">Calling Circle Name</label>
                                <input type="text" id="callingName" />
                            </div>
                            <div class="dropdown-call">
                                <label for="priority">Priority</label>
                                <select type="text" id="priority" class="dropdown-border">
                                    <option value="high">High</option>
                                    <option value="low">Low</option>
                                </select>
                            </div>
                            <div class="text-field">
                                <label for="icon">Icon</label>
                                <input type="text" id="icon" />
                            </div>

                            <div class="text-field-color">
                                <label for="colorPicker" class="color-label"> color</label>
                                <input type="color" id="colorPicker" class="color-picker" />
                            </div>

                        </div>
                    </div>

                    <div class="horizontal-block">
                        <h2>Contacts</h2>
                        <div class="contacts-row">
                            <div class="text-field">
                                <label for="contact">Selected Contacts</label>
                                <input type="text" id="contact" />
                            </div>
                            <div class="buttons-call">
                                <button class="add-contacts-btn">Add Contacts </button>

                            </div>
                        </div>
                    </div>

                    <div class="horizontal-block">
                        <h3>Call and Message Rules</h3>

                        <div class="label-border">
                            <span class="label-text">Call Rules</span>
                            <label for="Contacts">Contacts Communication</label>
                            <div class="button-container">
                                <a href="#D" class="boxed-button-one">Allow</a>
                                <a href="#D" class="boxed-button">Block</a>
                            </div>
                        </div>


                        <div class="inner-block">
                            <div class="label-border">
                                <span class="label-text">SMS Rules</span>
                                <label for="Contacts">Contacts Communication</label>
                                <div class="button-container">
                                    <a href="#D" class="boxed-button-one">Allow</a>
                                    <a href="#D" class="boxed-button">Block</a>
                                </div>
                            </div>
                        </div>

                        <div class="inner-block">
                            <div class="label-border" id="labelborder">
                                <span class="label-text">Block Apps</span>
                                <div class="horizontal-block-inner">


                                    {/* <div class="toggle-container">
                                        <div class="toggle-inner-container">
                                            <label class="toggle-label">Toggle 1</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div> */}

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe Acrobat </label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card"> 
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="card" id="card">
                                        <div class="toggle-container">
                                            <label class="toggle-label">Adobe scan</label>
                                            <label class="toggle">
                                                <input type="checkbox" />
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="horizontal-block">
                        <h2>Contacts</h2>
                        <div class="line-container">
                            <hr class="line" />
                        </div>
                        <div class="contacts-row">
                            <div class="buttons-call">
                                <button class="add-schedule-btn"> Add Schedule or Automation </button>
                            </div>
                        </div>
                    </div>

                    <div class="horizontal-block">
                        <h3>Media Settings</h3>
                        <div class="label-border">
                            <span class="label-text">Media Rules</span>
                            {/* <label for="Contacts">Protected By Album</label> */}
                            <div class="media-dropdown-call">
                                <label for="priority">Priority</label>
                                <select type="text" id="priority" class="media-dropdown-border">
                                    <option value="high">Family</option>
                                    <option value="low">Office</option>
                                    <option value="high">Profile</option>
                                    <option value="high">Private</option>
                                </select>
                            </div>
                        </div>
                    </div>


                    <div class="horizontal-block">
                        <h3>Generic Settings</h3>
                        {/* <div class="label-border"> */}
                            {/* <span class="label-text">Call Rules</span> */}
                            <label for="Contacts">Silent Mode</label>
                            <div class="button-container">
                                <a href="#D" class="boxed-button-one">ON</a>
                                <a href="#D" class="boxed-button">OFF</a>
                            </div>
                        {/* </div> */}
                    </div>

                    <div class="horizontal-block">
                        <div class="bw-buttons">
                        <button class="button-white">Back</button>
                        <button class="button-black">Save</button>
                        </div>
                    </div>


                </div>

            </div>


        </>
    )

}

export default Addcalling;
