import React from 'react';
import Header from './Header';
import './profile.css';
import { useLocation } from 'react-router-dom';
// import { Link } from 'react-router-dom';


const Profile = () => {
    const location = useLocation(); // Access the passed data from the location state
    const data = location.state?.data || {};
    return (
        <>
            <Header info={data.fname+' '+data.lname} info1={data.devicename} />
            <div className='mainContent'>
                <h1 style={{ fontWeight: 'bold' }}>My Account</h1>
                <button className='add-button'><a href='/Resetpassword' style={{color:'white'}}>+ Reset Password</a></button>
            </div>
            <div className='containerProfile'>
                <div className='MainPages'>
                    <div class="row m-1">
                        {/* Subscription */}
                        <div class="col-md-8">
                            <div className='subDiv'>
                                <div className='heading'style={{paddingTop:'20px'}}><h6 ><b>Subscription</b></h6></div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div>
                                            <ul style={{ margin: '20px' }}>
                                                <li><div class="item-content">
                                                    <div class="pt-2 pb-2 flex flex-row flex items-start justify-between">
                                                        <div class="item-title flex text-cap">
                                                            <mat-icon role="img" svgicon="mat_solid:person" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="person" data-mat-icon-namespace="mat_solid">
                                                                <svg viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M0 0h24v24H0z" fill="none"></path>
                                                                    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                                                                </svg>
                                                            </mat-icon>
                                                            <span class="pl-4">
                                                                <div class="ng-star-inserted">Name:</div>
                                                            </span>
                                                        </div>
                                                        <div class="item-before text-cap">STANDARD CITIZEN</div>
                                                    </div>
                                                </div>
                                                </li>
                                                <li><div class="item-content">
                                                    <div class="pt-2 pb-2 flex flex-row flex items-start justify-between">
                                                        <div class="item-title flex text-cap">
                                                            <mat-icon role="img" svgicon="mat_solid:av_timer" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="av_timer" data-mat-icon-namespace="mat_solid">
                                                                <svg viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                    <path d="M11 17c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1zm0-14v4h2V5.08c3.39.49 6 3.39 6 6.92 0 3.87-3.13 7-7 7s-7-3.13-7-7c0-1.68.59-3.22 1.58-4.42L12 13l1.41-1.41-6.8-6.8v.02C4.42 6.45 3 9.05 3 12c0 4.97 4.02 9 9 9 4.97 0 9-4.03 9-9s-4.03-9-9-9h-1zm7 9c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1zM6 12c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1z">
                                                                    </path>
                                                                </svg>
                                                            </mat-icon>
                                                            <span class="pl-4">
                                                                <div class="ng-star-inserted">Duration:</div>
                                                            </span>
                                                        </div>
                                                        <div class="item-before text-cap">12 Months</div>
                                                    </div>
                                                </div>
                                                </li>
                                                <li><div class="item-content">
                                                    <div class="pt-2 pb-2 flex flex-row flex items-start justify-between">
                                                        <div class="item-title flex text-cap">
                                                            <mat-icon role="img" svgicon="mat_solid:attach_money" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="attach_money" data-mat-icon-namespace="mat_solid">
                                                                <svg viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                    <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z"></path>
                                                                </svg></mat-icon><span class="pl-4">
                                                                <div class="ng-star-inserted"> Price:</div>
                                                            </span></div><div class="item-before text-cap">₹699.00</div>
                                                    </div>
                                                </div>
                                                </li>
                                                <li>
                                                    <div class="item-content">
                                                        <div class="pt-2 pb-2 flex flex-row flex items-start justify-between">
                                                            <div class="item-title flex text-cap" style={{ marginLeft: '5px' }}>
                                                                <mat-icon role="img" svgicon="heroicons_outline:chart-pie" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="chart-pie" data-mat-icon-namespace="heroicons_outline">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"></path>
                                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"></path>
                                                                    </svg></mat-icon><span class="pl-4">
                                                                    <div class="ng-star-inserted">Space:</div>
                                                                </span>
                                                            </div>
                                                            <div class="item-before text-cap">N.A </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li><div class="item-content">
                                                    <div class="pt-2 pb-2 flex flex-row flex items-start justify-between">
                                                        <div class="item-title flex text-cap">
                                                            <mat-icon role="img" svgicon="mat_solid:devices" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="devices" data-mat-icon-namespace="mat_solid">
                                                                <svg viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                                                    <path d="M4 6h18V4H4c-1.1 0-2 .9-2 2v11H0v3h14v-3H4V6zm19 2h-6c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zm-1 9h-4v-7h4v7z">
                                                                    </path>
                                                                </svg>
                                                            </mat-icon>
                                                            <span class="pl-4">
                                                                <div class="ng-star-inserted">Device:</div>
                                                            </span>
                                                        </div>
                                                        <div class="item-before text-cap">1</div>
                                                    </div>
                                                </div>
                                                </li>

                                            </ul>

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <ul style={{ margin: '20px' }}>
                                            <li>
                                                <div class="item-content">
                                                    <div class="pt-2 pb-2 flex flex-row flex items-start justify-between">
                                                        <div class="item-title flex text-cap">
                                                            <mat-icon role="img" svgicon="heroicons_outline:database" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="database" data-mat-icon-namespace="heroicons_outline">
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"></path>
                                                                </svg>
                                                            </mat-icon>
                                                            <span class="pl-4">
                                                                <div class="ng-star-inserted">Device Addon:</div>
                                                            </span>
                                                        </div>
                                                        <div class="item-before text-cap">N.A</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li><div class="item-content">
                                                <div class="pt-2 pb-2 flex flex-row flex items-start justify-between">
                                                    <div class="item-title flex text-cap">
                                                        <mat-icon role="img" svgicon="mat_solid:devices_other" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="devices_other" data-mat-icon-namespace="mat_solid">
                                                            <svg viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M3 6h18V4H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h4v-2H3V6zm10 6H9v1.78c-.61.55-1 1.33-1 2.22s.39 1.67 1 2.22V20h4v-1.78c.61-.55 1-1.34 1-2.22s-.39-1.67-1-2.22V12zm-2 5.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM22 8h-6c-.5 0-1 .5-1 1v10c0 .5.5 1 1 1h6c.5 0 1-.5 1-1V9c0-.5-.5-1-1-1zm-1 10h-4v-8h4v8z"></path>
                                                            </svg>
                                                        </mat-icon>
                                                        <span class="pl-4">
                                                            <div class="ng-star-inserted"> Device Addon Price:</div>
                                                        </span>
                                                    </div>
                                                    <div class="item-before text-cap">N.A</div>
                                                </div>
                                            </div>
                                            </li>
                                            <li><div class="item-content">
                                                <div class="pt-2 pb-2 flex flex-row flex items-start justify-between">
                                                    <div class="item-title flex text-cap">
                                                        <mat-icon role="img" svgicon="mat_solid:perm_device_information" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="perm_device_information" data-mat-icon-namespace="mat_solid">
                                                            <svg viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M13 7h-2v2h2V7zm0 4h-2v6h2v-6zm4-9.99L7 1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14z"></path>
                                                            </svg>
                                                        </mat-icon>
                                                        <span class="pl-4">
                                                            <div class="ng-star-inserted">Space Addon:</div>
                                                        </span>
                                                    </div><div class="item-before text-cap">N.A</div>
                                                </div>
                                            </div>
                                            </li>
                                            <li>
                                                <div class="item-content">
                                                    <div class="pt-2 pb-2 flex flex-row flex items-start justify-between">
                                                        <div class="item-title flex text-cap">
                                                            <mat-icon role="img" svgicon="mat_solid:space_dashboard" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="space_dashboard" data-mat-icon-namespace="mat_solid">
                                                                <svg viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                    <rect fill="none" height="24" width="24"></rect>
                                                                    <path d="M11,21H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h6V21z M13,21h6c1.1,0,2-0.9,2-2v-7h-8V21z M21,10V5c0-1.1-0.9-2-2-2h-6v7H21z"></path>
                                                                </svg></mat-icon>
                                                            <span class="pl-4">
                                                                <div class="ng-star-inserted">Space Addon Price:</div>
                                                            </span></div>
                                                        <div class="item-before text-cap">N.A</div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='blackbgAccent p-3'>
                                    <div >
                                        <ul class="purches-detils">
                                            <li><div class="item-content">
                                                <div class="pt-2 pb-2 flex flex-row flex items-start justify-start">
                                                    <div class="item-title flex text-cap">
                                                        <mat-icon role="img" svgicon="mat_solid:account_balance" class="mat-icon notranslate text-on-accent-900 mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="account_balance" data-mat-icon-namespace="mat_solid">
                                                            <svg viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><g><rect fill="none" height="24" width="24"></rect></g><g><g><rect height="7" width="3" x="4" y="10"></rect><rect height="7" width="3" x="10.5" y="10"></rect><rect height="3" width="20" x="2" y="19"></rect><rect height="7" width="3" x="17" y="10"></rect><polygon points="12,1 2,6 2,8 22,8 22,6"></polygon></g></g></svg></mat-icon>
                                                        <span class="pl-4"><div class="ng-star-inserted">Total Price:</div></span>
                                                    </div>
                                                    <div class="item-before text-cap pl-3">₹699.00</div>
                                                </div>
                                            </div>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                    <ul class="purches-detils">
                                        <li>
                                            <div class="item-content"><div class="pt-2 pb-2 flex flex-row flex items-start justify-start"><div class="item-title flex text-cap">
                                                <mat-icon role="img" svgicon="mat_solid:date_range" class="mat-icon notranslate text-on-accent-900 mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="date_range" data-mat-icon-namespace="mat_solid">
                                                    <svg viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path></svg>
                                                </mat-icon>
                                                <span class="pl-4"><div class="ng-star-inserted">Purchase  Date:</div></span>
                                            </div>
                                                <div class="item-before text-cap pl-3">Jul 1, 2023</div>
                                            </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="item-content">
                                                <div class="pt-2 pb-2 flex flex-row flex items-start justify-start">
                                                    <div class="item-title flex text-cap">
                                                        <mat-icon role="img" svgicon="mat_solid:update" class="mat-icon notranslate text-on-accent-900 mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="update" data-mat-icon-namespace="mat_solid">
                                                            <svg viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><g>
                                                                <rect fill="none" height="24" width="24" x="0"></rect></g><g><g><g>
                                                                    <path d="M21,10.12h-6.78l2.74-2.82c-2.73-2.7-7.15-2.8-9.88-0.1c-2.73,2.71-2.73,7.08,0,9.79s7.15,2.71,9.88,0 C18.32,15.65,19,14.08,19,12.1h2c0,1.98-0.88,4.55-2.64,6.29c-3.51,3.48-9.21,3.48-12.72,0c-3.5-3.47-3.53-9.11-0.02-12.58 s9.14-3.47,12.65,0L21,3V10.12z M12.5,8v4.25l3.5,2.08l-0.72,1.21L11,13V8H12.5z"></path>
                                                                </g></g></g>
                                                            </svg></mat-icon>
                                                        <span class="pl-4"><div class="ng-star-inserted">Expired Date:</div></span>
                                                    </div>
                                                    <div class="item-before text-cap pl-3">Jul 1, 2024</div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="item-content">
                                                <div class="pt-2 pb-2 flex flex-row flex items-start justify-start">
                                                    <div class="item-title flex text-cap">
                                                        <mat-icon role="img" svgicon="mat_solid:access_time" class="mat-icon notranslate text-on-accent-900 mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="access_time" data-mat-icon-namespace="mat_solid">
                                                            <svg viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
                                                                <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path></svg>
                                                        </mat-icon>
                                                        <span class="pl-4"><div class="ng-star-inserted">Remaining Days:</div></span>
                                                    </div>
                                                    <div class="item-before text-cap pl-3">216 day</div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class='btn-2'>
                                        <button className='chgPlan'>Change plan </button>
                                        <button className='rnwbtn'>Renew </button>
                                    </div>

                                </div>
                            </div>

                        </div>
                        {/* Change Profile Details */}
                        <div class="col-md-4">
                            <div className='chgProfile'>
                                <h6 className='heading'style={{paddingTop:'20px'}}><b>Change Profile Details</b></h6>
                                <div className='mainContentChgProf'>
                                    <ul style={{ margin: '20px' }}>
                                        <li>
                                            <div class="item-content">
                                                <div class="pt-4 flex flex-row flex items-start justify-between">
                                                    <div class="item-title flex text-cap">
                                                        <mat-icon role="img" svgicon="mat_outline:email" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="email" data-mat-icon-namespace="mat_outline">
                                                            <svg viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                <path d="M0 0h24v24H0V0z" fill="none"></path>
                                                                <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"></path>
                                                            </svg>
                                                        </mat-icon>
                                                        <span class="pl-4"><div class="ng-star-inserted">Email:</div></span>
                                                    </div>
                                                    <div class="item-before">sushant@aivot.in</div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="item-content">
                                                <div class="pt-2 pb-2 flex flex-row flex items-start justify-between">
                                                    <div class="item-title flex text-cap">
                                                        <mat-icon role="img" svgicon="heroicons_outline:identification" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="identification" data-mat-icon-namespace="heroicons_outline">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"></path>
                                                            </svg>
                                                        </mat-icon>
                                                        <span class="pl-4 text-cap"><div class="ng-star-inserted">First Name:</div></span>
                                                    </div>
                                                    <div class="item-before text-cap">Sushant</div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="item-content">
                                                <div class="pt-2 pb-2 flex flex-row flex items-start justify-between">
                                                    <div class="item-title flex text-cap">
                                                        <mat-icon role="img" svgicon="heroicons_outline:newspaper" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="newspaper" data-mat-icon-namespace="heroicons_outline">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"></path>
                                                            </svg>
                                                        </mat-icon>
                                                        <span class="pl-4 text-cap"><div class="ng-star-inserted">Last Name:</div></span>
                                                    </div>
                                                    <div class="item-before text-cap">Borhade</div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="item-content">
                                                <div class="pt-2 pb-2 flex flex-row flex items-start justify-between">
                                                    <div class="item-title flex text-cap">

                                                    <div className='icn' style={{width:'16px',height:'16px'}}><i class="fa fa-qrcode" aria-hidden="true"></i></div>
                                                        <span class="pl-4 text-cap">
                                                            <div class="ng-star-inserted">Referral Code:</div>
                                                        </span></div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div class="item-before mr-1">MdGsFR </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='BtnContainer'>
                                                <button className='editButton'>
                                                    <div>
                                                        <mat-icon role="img" class="mat-icon notranslate icon-size-5 mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="pencil" data-mat-icon-namespace="heroicons_solid">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                                                            </svg>
                                                        </mat-icon>
                                                       <a href='/Editprofile' style={{color:'white'}}> Edit Profile</a>
                                                    </div>
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                             </div>
                              {/* Delete Account */}
                             <div className='overflow-hidden'>
                                <div className='delContent'>
                                <h5><b>Delete Account</b></h5>
                                <div class="ng-star-inserted">Once you delete your account, all of your information and setting will lost forever. We will not be able to restore your account, Are you sure you want to proceed?</div>
                                </div>
                                <div className='delBtn'><button className='BTN-DEL'>Delete Forever</button></div>
                             </div>
                        </div>
                    </div>
                </div>
                <div class="row m-1">
                    {/*  Device Addon*/}
                    <div class="col-md-6">
                    <div className='deviceAdon' >
                        <div className='bx-1'>
                        <div className='divcAd'><h6><b>Device Addon</b></h6></div>
                        <div className='lbl1'><label>Do you want to add extra devices in your current subscription?</label></div>
                        <div className='mn-tbl-1'>
                        <table class="table" >
                            <thead class="thead-light">
                                <tr>
                                <th scope="col">Device</th>
                                <th scope="col">Duration</th>
                                <th scope="col">Price</th>
                                <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>Device 1</td>
                                <td>12 Month</td>
                                <td>₹299.00</td>
                                <td><div className='atn-btn-3'>
                                    <button className='btn-act-tbl'>Buy
                                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                    </button>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td>2 Device</td>
                                <td>12 Month</td>
                                <td>₹599.00</td>
                                <td>
                                <div className='atn-btn-3'>
                                    <button className='btn-act-tbl'>Buy
                                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                    </button>
                                </div>
                                </td>
                                </tr>
                                <tr>
                                <td>3 Device</td>
                                <td>12 Month</td>
                                <td>₹1,199.00</td>
                                <td>
                                <div className='atn-btn-3'>
                                    <button className='btn-act-tbl'>Buy
                                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                    </button>
                                </div>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>    
                    </div>
                    {/* Cloud Storage Space Addon */}
                    <div class="col-md-6">
                    <div className='cloudStorage' >
                        {/* <div className='sto-Spc'><h1>Cloud Storage Space Addon</h1></div>
                        <div className='lbl1'><label>Do you want to add extra cloud storage space in your current subscription?</label></div> */}
                         <div className='bx-1'>
                        <div className='divcAd'><h6><b>Cloud Storage Space Addon</b></h6></div>
                        <div className='lbl1'><label>Do you want to add extra cloud storage space in your current subscription?</label></div>
                        <div className='mn-tbl-1'>
                        <table class="table" >
                            <thead class="thead-light">
                                <tr>
                                <th scope="col">Cloud Space</th>
                                <th scope="col">Duration</th>
                                <th scope="col">Price</th>
                                <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>Basic 30 GB</td>
                                <td>12 Month</td>
                                <td>₹999.00</td>
                                <td><div className='atn-btn-3'>
                                    <button className='btn-act-tbl'>Buy
                                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                    </button>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td>Standard 50 GB</td>
                                <td>12 Month</td>
                                <td>₹1,499.00</td>
                                <td>
                                <div className='atn-btn-3'>
                                    <button className='btn-act-tbl'>Buy
                                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                    </button>
                                </div>
                                </td>
                                </tr>
                                <tr>
                                <td>Premium 100 GB</td>
                                <td>12 Month</td>
                                <td>₹3,600.00</td>
                                <td>
                                <div className='atn-btn-3'>
                                    <button className='btn-act-tbl'>Buy
                                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                    </button>
                                </div>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                    </div>    
                </div>
                {/* Referral Customers */}
                <div class='row m-4'>
                    <div className='ref-cust'>
                     <div className='divcAd'><h6><b>Referral Customers</b></h6></div>
                    Total Referral (0) ,  Active Referral (0)
                    </div>
                </div>
                {/* footer */}
                <div>
                
                    <div className='ftr'>
                       <div className='f1'>uat_in 1.0.0</div>
                       <div className='f2'>AIEGS © 2023</div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Profile
