import React, { useState } from "react";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
//import Header from './Header.js'
import "./Loginregister.css";
import Logo from "./Logo";
import {
  collection,
  query,
  where,
  getDocs,
  documentId,
} from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("viha@gmail.com");
  const [password, setPassword] = useState("viha123");
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const getUserDetailsById = async (userId) => {
    console.log(userId);
    const usersCollection = collection(db, "AigesCollection");
    console.log(usersCollection);
    const userQuery = query(usersCollection, where(documentId(), "==", userId));
    console.log(userQuery);
    console.log();

    const querySnapshot = await getDocs(userQuery);
    var data;
    // console.log(q);
    querySnapshot.forEach((element) => {
      data = element.data();
      console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
      console.log(data);
      console.log(data.lname);
      console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
    });
    return data; // Return the data
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your authentication logic here (e.g., API call, validation, etc.)
    console.log("Email:", email);
    console.log("Password:", password);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;

        // Assuming you have the user ID stored in a variable called userId
        const userId = user.uid;



        getUserDetailsById(userId)
          .then((userData) => {
            console.log("User Details:", userData.fname);
            console.log("User hshsh:", userData.lname);
            
            let data = {
              fname: userData.fname, 
              lname: userData.lname ,
              devicename: userData.devicename, 
              brandname: userData.brandname, 
              modelname: userData.modelname, 
              osversion: userData.osversion,    
              totalcallingcircles: userData.totalcallingcircles,
              activecallingcircle: userData.activecallingcircle,
              activeplan: userData.activeplan,
              expiryon: userData.expiryon,
              useddevice: userData.useddevice,
              usedspace: userData.usedspace,
              Calls: userData.Calls,
              Messages: userData.Messages,
              Contacts: userData.Contacts,
              emergencycontacts: userData.emergencycontacts,
              Media: userData.Media,
             
            };
            navigate("/Dashboard", { state: { data } });
          })
          .catch((error) => {
            console.error(error.message);
          });
        // navigate("/Dashboard");
      })
      .catch((error) => {
        setError(error.message);
        console.error("Login error:", error.message);
      });
  };

  return (
    <div className="background-section">
      <div className="background">
        <div className="middle">
          <div className="block">
            <div className="logo">
              {/* <img src="logo.png" alt="Logo"/> */}
              <Logo />
            </div>
            <h2>SIGN IN TO YOUR ACCOUNT</h2>
            <form onSubmit={handleSubmit}>
              {/* <Header/> */}

              <div>
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
              </div>
              {error && <p style={{ color: "red" }}>{error}</p>}

              <a href="/Forgotpassword" className="forgot-password">
                Forgot password?
              </a>
              <br></br>
              <button className="login-button">Sign In</button>
              <p>
                Don't have an account? <a href="/Register">Create an account</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
