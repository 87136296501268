import React, { useState } from 'react'
import "./navbar.css"
import Header from './Header'
import 'font-awesome/css/font-awesome.min.css';

import Modal from 'react-modal';
import "./Modal.css";
import { db } from '../firebase';
// import { useState } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useRef } from "react";
import { query, orderBy, limit } from "firebase/firestore";
import { useLocation } from 'react-router-dom';


const Calling = () => {
    const location = useLocation(); // Access the passed data from the location state
    const data = location.state?.data || {};
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }
    const wasCalled = useRef(false);
    const [info, setInfo] = useState([]);
    useEffect(() => {
        if (wasCalled.current) return;
        wasCalled.current = true;
        Fetchdata();
    }, []);

    const Fetchdata = async () => {

        const q = query(collection(db, "callingCln"), orderBy("name", "contacts", "active", "priority"), limit(100));
        const querySnapshot = await getDocs(q);
        // console.log(q);
        querySnapshot.forEach(element => {
            var data = element.data();
            setInfo(arr => [...arr, data]);

        });

    }

    return (

        <>
            <Header info={data.fname+' '+data.lname} info1={data.devicename} />


            <div className="calling-circle-container">

                <div className="headline">
                    <h1>Calling Circles</h1>

                    <div className="buttons">
                        <button className="add-button" onClick={openModal}> + Emergency Contacts(0)</button>
                        <button className="add-button"><a href='/Addcalling'>+ Add Calling Circle</a></button>
                    </div>

                </div>
                {/* Modal */}
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                    style={{
                        content: {
                            width: '50%',
                            height: '90vh',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            borderRadius: '01rem',
                            paddingBottom: '0%',
                            marginBottom: '2px',
                            overflowY: 'hidden',
                        }
                    }}
                >
                    {/* Your modal content goes here */}
                    <div className='modal-overlay'>
                        <button className="close-button" onClick={closeModal}>
                            X
                        </button>
                        <div className="topHeding" >
                            <h2 className='headingCnt'>Please Add Contacts</h2>
                        </div>
                        <div className="search-bar">
                            <label>search</label>
                            <input type="text" placeholder="Search contacts" style={{ width: '50%' }} />
                        </div>
                        <div className="show-contectList" style={{ overflowY: 'scroll', height:'300px'}}>
                            <table style={{ width: '100%' }} mat-table matsort className="mat-table cdk-table mat-sort">
                                <thead style={{ backgroundColor: 'black', width: '100%', height: '50px' }}>
                                    <tr style={{ color: 'white', marginLeft: '10px' }}>
                                        <th>Name</th>
                                        <th>Phones</th>
                                        <th><input type="checkbox" /></th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <tr className='myrow'>
                                        <td>Ajit Jadhav</td>
                                        <td>+91 90963 64651</td>
                                        <td><input type="checkbox" /></td>
                                    </tr>
                                    <hr style={{ margin: '2px' }} />
                                    <tr className='myrow'>
                                        <td>Ajit Jadhav</td>
                                        <td>+91 90963 64651</td>
                                        <td><input type="checkbox" /></td>
                                    </tr>
                                </tbody>
                            </table>


                        </div>
                        <div class='btncntContainer'>
                            <button class='cl-button'>Close</button>
                            <button class='save-button'>Save</button>
                        </div>
                    </div>
                </Modal>
                {/* Modal Close */}

                <table className="calling-circle-table">
                    <tr>
                        <th>Color</th>
                        <th>Name</th>
                        <th>Contacts</th>
                        <th>Priority</th>
                        <th>Active</th>
                        <th>Actions</th>
                    </tr>
                    {
                        info.map((data) => (
                            <Frame name={data.name}
                                contacts={data.contacts}
                                priority={data.priority}
                                active={data.active} />
                        ))
                    }


                    {/* <tr>
                        <td><div className="circle orange"></div></td>
                        <td>Personal</td>
                        <td>(0) <i className="fa fa-user"></i></td>
                        <td>Low</td>
                        <td>No</td>
                        <td>
                            <i className="fa fa-eye"></i>
                            <i className="fa fa-pencil"></i>
                            <i className="fa fa-trash"></i>
                        </td>
                    </tr>
                    <tr>
                        <td><div className="circle red"></div></td>
                        <td>Office</td>
                        <td>(0) <i className="fa fa-user"></i></td>
                        <td>Low</td>
                        <td>No</td>
                        <td>
                            <i className="fa fa-eye"></i>
                            <i className="fa fa-pencil"></i>
                            <i className="fa fa-trash"></i>
                        </td>
                    </tr>
                    <tr>
                        <td><div className="circle green"></div></td>
                        <td>Home</td>
                        <td>(1) <i className="fa fa-user"></i></td>
                        <td>Low</td>
                        <td>No</td>
                        <td>
                            <i className="fa fa-eye"></i>
                            <i className="fa fa-pencil"></i>
                            <i className="fa fa-trash"></i>
                        </td>
                    </tr> */}
                </table>
            </div>
        </>


    )
}
const Frame = ({ name, contacts, priority, active }) => {
    console.log(name + " " + contacts + " " + priority + " " + active);
    return (
        <tr>


            <td><div className="circle orange"></div></td>
            <td> {name}</td>
            <td>({contacts})<i className="fa fa-user"></i> </td>
            <td>{priority}</td>
            <td>{active}</td>
            <td>
                <i className="fa fa-eye"></i>
                <i className="fa fa-pencil"></i>
                <i className="fa fa-trash"></i>
            </td>

        </tr>


    );
}
export default Calling
