// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth}  from "firebase/auth";
import 'firebase/firestore';
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDdJgoFDNuOsd_Gh-aJDui-5pmxU-4S_PU",
  authDomain: "aiegs-web-25a8b.firebaseapp.com",
  projectId: "aiegs-web-25a8b",
  storageBucket: "aiegs-web-25a8b.appspot.com",
  messagingSenderId: "834715687704",
  appId: "1:834715687704:web:ea04bb9ef6d44de41a5c6d",
  measurementId: "G-KPWML1HLJC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth= getAuth(app);
const db = getFirestore(app);

export {auth};
export {db};