import React from 'react'
import "./navbar.css"
import Header from './Header'
import { useLocation } from 'react-router-dom';



const Callhistory = () => {
const location = useLocation(); // Access the passed data from the location state
const data = location.state?.data || {};
  return (
    
   <>
   <Header info={data.fname+' '+data.lname} info1={data.devicename}/>
   
   <div class="order-container">
   
        <div class="order-headline">
            <h1>Call History</h1>
        </div>
        
        
        <table class="order-table">
            <tr>
                <th>Name</th>
                <th>Contact No</th>
                <th>Date</th>
                <th >Duration</th>
                <th>Time</th>
                
            </tr>
            
            <tr>
                
                <td></td>
                <td></td>
                <td></td>
                <td></td>               
                <td></td>
                
            </tr>
           
            
        </table>
    </div>
   </>
   
   
  )
}

export default Callhistory
