import React from "react";
import "./demostyle.css";

import Header from "./Header.js";
import { useLocation } from "react-router-dom";
import Page1 from "./Location.js";
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const location = useLocation(); // Access the passed data from the location state
  const data = location.state?.data || {};
 

  return (
    <>
      <Header info={data.fname + " " + data.lname} info1={data.devicename} />
     
      <div className="bg-card">
        <div className="flex flex-col w-full max-w-screen-xl mx-auto px-6 sm:px-8">
          <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 my-4">
            <div className="flex flex-auto items-center min-w-0">
              <div
                id="usericon" className="flex-0 w-15 h-15 rounded-full overflow-hidden admin_user"
               
              >
                <span >S</span>
              </div>
              <div className="flex flex-col min-w-0 ml-4">
                <div className="custom-text-style">
                  Welcome back, {data.fname}!
                </div>
              </div>
            </div>
          </div>
          <div
            className="relative flex self-start pt-2 pb-1 pl-5 pr-4 cursor-pointer overflow-hidden rounded-t-xl border border-b-0 bg-default"
            id="myElement"
          >
            <div className="flex items-center">
              <div className="overflow-hidden">
                <div className="font-medium leading-6 truncate">
                  <div
                    className="font-medium leading-6 truncate ng-star-inserted"
                    id="myElement"
                  >
                    Dashboard
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-auto border-t -mt-px pt-4 sm:pt-6" id="myElement">
        <div className="w-full max-w-screen-xl mx-auto">
          <div className="dashborad-inner">
            <div className="flex flex-row flex items-start justify-between md-display-block project-dashborad">
              <div className="col-4">
                <div className="card" id="myElement1">
                  <div className="text-lg font-bold tracking-tight leading-6 truncate pb-1">
                    <div
                      className="text-lg sm-f14 font-bold tracking-tight leading-6 truncate pb-1 ng-star-inserted"
                      id="devicesummery"
                    >
                      Device Summary
                    </div>
                    <br />
                    <h1 className="textwithicon">
                      <i className="fa fa-mobile-phone" id="icon"></i>
                      &nbsp;&nbsp; Device
                      Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {data.devicename}
                    </h1>
                    <br />
                    <h1 className="textwithicon">
                      <i className="fa fa-rocket" id="icon"></i>
                      &nbsp; Brand
                      Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {data.brandname}
                    </h1>
                    <br />
                    <h1 className="textwithicon">
                      <i className="fa fa-cog" id="icon"></i>
                      &nbsp; Model
                      Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {data.modelname}
                    </h1>
                    <br />
                    <h1 className="textwithicon">
                      <i className="fas fa-desktop" id="icon"></i>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; OS
                      Version&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {data.osversion}
                    </h1>{" "}
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="card" id="myElement1">
                  <div className="text-lg font-bold tracking-tight leading-6 truncate pb-1">
                    <div
                      className="text-lg sm-f14 font-bold tracking-tight leading-6 truncate pb-1 ng-star-inserted"
                      id="devicesummery"
                    >
                      Calling Circle Summary
                    </div>
                    <br />
                    <h1 className="textwithicon">
                      <i className="fas fa-mobile" id="icon"></i>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Total Calling
                      Circles&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{data.totalcallingcircles}
                    </h1>
                    <br />
                    <h1 className="textwithicon">
                      <i className="fas fa-mobile" id="icon"></i>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Active Calling
                      Circle&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{data.activecallingcircle}
                    </h1>
                    <br />
                  </div>

                  <button
                    type="button"
                    id="changecallingcircle"
                    className="btn btn-primary"
                  >
                    Change Calling Circle
                  </button>
                </div>
              </div>

              <div className="col-4">
                <div className="card" id="myElement1">
                  <div className="text-lg font-bold tracking-tight leading-6 truncate pb-1">
                    <div
                      className="text-lg sm-f14 font-bold tracking-tight leading-6 truncate pb-1 ng-star-inserted"
                      id="devicesummery"
                    >
                      Plan Summary
                    </div>
                    <br />
                    <h1 className="textwithicon">
                      <i className="fas fa-mobile" id="icon"></i>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Active
                      Plan&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{data.activeplan}
                      
                    </h1>
                    <br />
                    <h1 className="textwithicon">
                      <i className="fa fa-database" id="icon"></i>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Expiry
                      On&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{data.expiryon}
                     
                    </h1>
                    <br />
                    <h1 className="textwithicon">
                      <i className="fas fa-mobile" id="icon"></i>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Used
                      Devices&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{data.useddevice}
                    </h1>
                    <br />
                    <h1 className="textwithicon">
                      <i className="fas fa-mobile" id="icon"></i>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Used
                      Space&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{data.usedspace}
                    
                    </h1>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="card" id="mycard">
              <div className="text-lg font-bold tracking-tight leading-6 truncate pb-1">
                <div
                  className="text-lg sm-f14 font-bold tracking-tight leading-6 truncate pb-1 ng-star-inserted"
                  id="devicesummery"
                >
                  Phone Activities
                </div>
              </div>
              <div>
                <div
                  className="row"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div style={{ margin: 10 }}>
                    <i className="fa fa-phone" id="icon"></i>
                  </div>
                  <div className="column m-1" style={{ flex: 1 }}>
                    <div>Calls</div>
                    <div className="red-line"></div>
                  </div>
                  <div style={{ margin: 22 }}>{data.Calls}</div>
                </div>
                <div
                  className="row"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div style={{ margin: 10 }}>
                    <i className="fas fa-comments" id="icon"></i>
                  </div>
                  <div className="column m-1" style={{ flex: 1 }}>
                    <div>Messages</div>
                    <div className="blue-line"></div>
                  </div>
                  <div style={{ margin: 22 }}>{data.Messages}</div>
                </div>
                <div
                  className="row"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div style={{ margin: 10 }}>
                    <i className="fa fa-map-marker" id="icon"></i>
                  </div>
                  <div className="column m-1" style={{ flex: 1 }}>
                    <div>Contacts</div>
                    <div className="blue-line"></div>
                  </div>
                  <div style={{ margin: 22 }}>{data.Contacts}</div>
                </div>
                <div
                  className="row"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div style={{ margin: 10 }}>
                    <i className="	fa fa-users" id="icon"></i>
                  </div>
                  <div className="column m-1" style={{ flex: 1 }}>
                    <div>Emergency Contacts</div>
                    <div className="blue-line"></div>
                  </div>
                  <div style={{ margin: 22 }}>{data.emergencycontacts}</div>
                </div>
                <div
                  className="row"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div style={{ margin: 10 }}>
                    <i className="	fa fa-file-photo-o" id="icon"></i>
                  </div>
                  <div className="column m-1" style={{ flex: 1 }}>
                    <div>Media</div>
                    <div className="blue-line"></div>
                  </div>
                  <div style={{ margin: 22 }}>{data.Media}</div>
                </div>
              </div>
            </div>

            <div className="card" id="mycard2">
              <div
                className="text-lg sm-f14 font-bold tracking-tight leading-6 truncate pb-1 ng-star-inserted"
                id="devicesummery"
              >
                Current Location
              </div>
              <div>
                <script
                  async
                  defer
                  src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDwaXa3JZsFqv71812tm1k5FokRzLrX0RM&callback=initMap"
                ></script>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
