import React from 'react'
import Header from './Header'
import { db } from '../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useRef } from "react";
import { query, orderBy, limit } from "firebase/firestore";
import { useLocation } from 'react-router-dom';

const Location = () => {
  const location = useLocation(); // Access the passed data from the location state
  const data = location.state?.data || {};
  const wasCalled = useRef(false);
  const [info, setInfo] = useState([]);
  useEffect(() => {
    if (wasCalled.current) return;
    wasCalled.current = true;
    Fetchdata();
  }, []);
  const Fetchdata = async () => {
    const q = query(collection(db, "locationHistory"), orderBy("address", "time"), limit(100));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(element => {
      var data = element.data();
      setInfo(arr => [...arr, data]);

    });
  }

  return (
    <>
      <Header  info={data.fname+' '+data.lname} info1={data.devicename} />
      <div className="location-site">
        <div className="contact-container">
          <h1>Location History</h1>
          10 Location History
        </div>
        <div>
          <table className="table table-hover">
            <thead>
              <tr style={{ backgroundColor: "#f3f6fa", visibility: "inherit" }}>
                <th style={{ width: "931.7", pxpaddingBottom: "2px", textOverflow: "ellipsis", textAlign: "Center", marginLeft: "4px", marginRight: "2px", lineHeight: "45px" }} scope="col">Address</th>
                <th style={{ width: "266.2px", pxpaddingBottom: "2px", marginLeft: "4px", marginRight: "2px", lineHeight: "45px" }} scope="col">Location Time</th>
                <th style={{ width: "133.1px", lineHeight: "35px" }} scope="col">Actions</th>
              </tr>
            </thead>
            {
              info.map((data) => (
                <Frame
                  address={data.address}
                  time={data.time} />
              ))
            }

          </table>
        </div>
      </div>

    </>
  )
}
const Frame = ({ address, time }) => {
  console.log(address + " " + time);
  return (
    <tr>

      <td style={{ textAlign: 'center' }}>  {address}  </td>
      <td style={{ marginLeft: '-60px' }}><i className="fa fa-phone"></i> {time}</td>
      <td><FontAwesomeIcon icon={faMapMarker} size="2x" /></td>
    </tr>


  );
}

export default Location
