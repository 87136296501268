import React, { useState } from 'react';
import Header from './Header';
// import './Resetpassword.css'; // Import your CSS file for styling

const Resetpassword = () => {
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = () => {
    if (!fname || !lname) {
      setError('Please fill this fields');
    
    } else {
      // Your reset password logic here
      // ...
      // Clear the error and reset the form
      setError('');
      setFname('');
      setLname('');
    }
  };

  return (
    <>
      <Header />
      <div className="main-sec">
        <header>
          <h1>Edit Calling circle</h1>
        </header>

        <div className="container">
          <div
            className="horizontal-block"
            style={{ marginTop: '0px', marginBottom: '15rem', marginLeft: '-10px' }}
          >
            <div className="flex-container">
              <div className={`text-field ${error && !fname ? 'error' : ''}`}>
              <label for="callingName">FirstName</label>
                <input
                  type="text"
                  id="callingName"
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                />
                {error && !fname && <div className="error-message">{error}</div>}
              </div>

              <div className={`text-field ${error && !lname ? 'error' : ''}`}>
              <label for="icon">LastName</label>
                <input
                  type="text"
                  id="icon"
                  value={lname}
                  onChange={(e) => setLname(e.target.value)}
                />
                {error && !lname && <div className="error-message">{error}</div>}
              </div>

              <div className="bw-buttons" style={{ marginTop: '120px', marginLeft: '10rem' }}>
                <button className="button-white"><a href="/Profile">Back</a></button>
                <button className="button-black" onClick={handleSubmit}>
                  Edit Calling Circle
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resetpassword;
