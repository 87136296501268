import React from 'react'

const Logo = () => {
  return (
    <>
       <div className="logo">
          <img src="./logo11.png" alt="Logo" style={{height:'70px', width:'110px'}} />
       </div>
    </>
  )
}

export default Logo
