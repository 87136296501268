import React from 'react';
import Dashboard from './component/Dashboard.js';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Contact from './component/Contact.js';
import Media from './component/Media.js';
import Messages from './component/Messages.js';
import Callhistory from './component/Callhistory.js';
import Location from './component/Location.js';
import Calling from './component/Calling.js'; 
import Login from './component/Login.js';
import Register from './component/Register.js';
import Forgotpassword from './component/Forgotpassword.js';
import Profile from './component/Profile.js';
import Resetpassword from './component/Resetpassword.js';
import Editprofile from './component/Editprofile.js';
import Addcalling from './component/Addcalling.js';
import Purchases from './component/Purchases.js';
// import Forgotpassword from './components/Forgotpassword';


const App = () => {
  return (

    <div className="App">
     
      <Router>
      
        <Routes>
          <Route path='/' element={<Login/>}/>
          <Route path='/Register' element={<Register/>}/>
          <Route path='/Forgotpassword' element={<Forgotpassword/>}/>
          <Route path='/Dashboard' element={<Dashboard/>}/>
          <Route path='/Calling' element={<Calling />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/Media' element={<Media />} />
          <Route path='/Messages' element={<Messages />} />
          <Route path='/Callhistory' element={<Callhistory />} />
          <Route path='/Location' element={<Location />} />
          <Route path='/Profile' element={<Profile />} />
          <Route path='/Resetpassword' element={<Resetpassword />} />
          <Route path='/Editprofile' element={<Editprofile />} />
          <Route path='/Addcalling' element={<Addcalling />} />
          <Route path='/Purchases' element={<Purchases />} />
        </Routes>
       
      </Router>
    </div>
  )
}

export default App
