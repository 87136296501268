import React from 'react'
import "./navbar.css"
import Header from './Header'
import { db } from '../firebase';
import { useState } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useRef } from "react";
import { query, orderBy, limit } from "firebase/firestore";
import { useLocation } from 'react-router-dom';


const Purchases = () => {
    const location = useLocation(); // Access the passed data from the location state
    const data = location.state?.data || {};
    const wasCalled = useRef(false);
    const [info, setInfo] = useState([]);
    useEffect(() => {
        if(wasCalled.current) return;
            wasCalled.current = true;
        Fetchdata();
      }, []);

      const Fetchdata = async () => {

       
        
        const q = query(collection(db, "purchases"), orderBy("orderno","orderdate","totalamount","status"), limit(100));
        const querySnapshot = await getDocs(q);
       // console.log(q);
        querySnapshot.forEach(element => {
            var data = element.data();
            setInfo(arr => [...arr, data]);

        });
      }    
  return (
    
   <>
   <Header info={data.fname+' '+data.lname} info1={data.devicename}/>
   
   <div class="order-container">
   
        <div class="order-headline">
            <h1>Purchases</h1>
        </div>
        
        
        <table class="order-table">
            <tr>
                <th>Order No.</th>
                <th>order Date</th>
                <th>Total Amount</th>
                <th >Status</th>
                <th>Actions</th>
                
            </tr>
            {
                info.map((data) => (
                    <Frame orderno={data.orderno}
                        orderdate={data.orderdate}
                        totalamount={data.totalamount}
                        status={data.status} />
                ))
            }
            {/* <tr>
                <td>P20005678</td>
                <td>Jul, 02, 2023, 01:58:54</td>
                <td>699.00</td>
                <td>Order Payment Success</td>
                
                <td class='icons-bg'>
                    <a href='/Viewcalling'><i class="fa fa-eye"></i></a>
                </td>
            </tr> */}
            
        </table>
    </div>
   </>
   
   
  )
}
const Frame = ({ orderno, orderdate, totalamount,status }) => {
    console.log(orderno + " " + orderdate + " " + totalamount + " " + status);
    return (
           <tr>
                
                
                <td>{orderno}</td>
                   <td>{orderdate} </td>
                    <td>{totalamount} </td>
                    <td>{status}</td> 
                    <td class='icons-bg'>
                    <a href='/Viewcalling'><i class="fa fa-eye"></i></a>
                </td>       
          </tr>

            
    );
}
export default Purchases
