import React from "react";
//import "./Loginregister.css";
import Logo from "./Logo";
import { sendPasswordResetEmail } from "firebase/auth";
import { db } from "../firebase";

const Forgotpassword = () => {

    const handleSubmit = async (e)=>{
        e.preventDefault()
        const emailval = e.target.email.value;
        sendPasswordResetEmail(db,emailval).then(data=>{
            console.log("data",data)
            alert("check your gmail")
        }).catch(err=>{
            alert(err)
            console.log("error",err);
            
        })

    }
  return (
    <>
      <div className="background-section">
        <div className="middle-section">
          <div className="block-section">
            <div className="logosize">
              <Logo />
            </div>
            <h2>FORGOT YOUR PASSWORD?</h2>

            <form onSubmit={(e)=>handleSubmit(e)}>
              <div className="form-group-section">
                <label for="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                />
              </div>

              <div className="form-group-section">
                <a href="/Login" className="Back-to-Login-part">
                  Back to Login
                </a>
                <button className="submit-botton">Submit</button>
              </div>
            </form>

            <p>
              Enter your username or email address and we will send you
              instructions on how to create a new password.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgotpassword;
